/* eslint-disable no-unreachable */
import apiEndpoints from 'apiEndpoints'
import { debug } from 'utils/log'
import { PLATFORM_HOST } from 'utils/constants'

// empty right now but we may want to consistently use a standard
// prefix across all API calls. Something like `/api/5.0/` so that we
// can eventually version the API endpoints
const urlPrefix = PLATFORM_HOST

const Supernova = window.Supernova
const localStorage = window.localStorage

export const learnKitAuth = (args) => {
  const {
    courseKey,
    callback,
    userAq,
    userLk,
    dispatch,
    setError,
    setLoading,
    LEARNKIT_ERROR_CODES,
  } = args
  debug('userAq', userAq)
  debug('userLk', userLk)
  const retryAuth =
    localStorage.getItem('retryAuth') !== null ? localStorage.getItem('retryAuth') : true

  if (Supernova) {
    window.Supernova?.User?.getInfo().then((userResponse) => {
      debug('userResponse', userResponse)
      if (userResponse?.ok && userResponse?.status === 200 && userAq?.learnkit_has_access_token) {
        const res = userResponse?.data
        debug('userResponse.data', res)

        if (userLk && userLk.sessionId === res?.sessionId) {
          localStorage.removeItem('retryAuth')
          callback()
          return
        }

        if (
          (userLk && userLk.email === res?.email) ||
          userAq.learnkit_user_email === res?.email ||
          userAq.email === res?.email
        ) {
          debug('emails match')

          if (res.eulaAccepted) {
            dispatch({
              type: 'USER_LK_SET',
              payload: { sessionId: res?.sessionId },
            })
            callback()
          } else {
            // Redirect to EULA page
            const eulaRedirect = `${urlPrefix}${apiEndpoints().eulaRedirect(
              courseKey,
              window.location.href,
            )}`
            window
              .fetch(eulaRedirect, { credentials: 'include' })
              .then((response) => {
                if (response.ok) {
                  return response.json()
                }
                throw Error(response.statusText)
              })
              .then((data) => {
                // handle success
                const eulaUrl = data.url
                window.open(eulaUrl, '_self')
              })
          }

          localStorage.removeItem('retryAuth')
          return
        }

        // else...
        debug('signout')
        Supernova.User.signOut().then(() => learnKitAuth(args))
      } else if (retryAuth === 'false') {
        const error = userResponse?.error
        debug('error:', error)
        const message = `LearnKit authentication error: ${error}`
        setError &&
          setError({
            code: LEARNKIT_ERROR_CODES.LEARNKIT_AUTH_ERROR,
            data: { message },
          })
        setLoading && setLoading(false)
        localStorage.removeItem('retryAuth')
      } else {
        // Not authenticated to LK
        setLoading && setLoading(true)
        const currentLocation = window.location.href
        // Needs to sign in
        const lkAuthEndpoint = `${urlPrefix}${apiEndpoints().learnKitAuth(
          courseKey,
          currentLocation,
        )}`
        window
          .fetch(lkAuthEndpoint, { credentials: 'include' })
          .then((response) => {
            if (response.ok) {
              return response.json()
            }
            throw Error(response.statusText)
          })
          .then((data) => {
            // handle success
            localStorage.setItem('retryAuth', 'false')
            // Open SSO Url, which will redirect to `currentLocation` defined
            // above upon authentication
            const ssoUrl = data.sso_url
            window.open(ssoUrl, '_self')
          })
          .catch((error) => {
            const message = `There was a problem with the fetch operation: ${error.message}.`
            setError &&
              setError({
                code: LEARNKIT_ERROR_CODES.LEARNKIT_AUTH_FETCH_FAILED,
                data: { message, error },
              })
            setLoading && setLoading(false)
            localStorage.removeItem('retryAuth')
          })
      }
    })
  }
}

export const handleLinksToBookshelf = ({
  e,
  linkHref,
  isAuthenticating,
  setIsAuthenticating,
  userAq,
  userLk,
  dispatch,
  courseKey,
}) => {
  e && e.preventDefault()
  if (!isAuthenticating) {
    setIsAuthenticating(true)
    // authenticate to LearnKit and then open the link in a new tab
    const authCallback = () => {
      setIsAuthenticating(false)
      window.open(linkHref, '_blank')
    }
    learnKitAuth({ callback: authCallback, userAq, userLk, dispatch, courseKey })
  }
}
